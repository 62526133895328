import {
	ERROR_INVALID_KEYCODE_LENGTH,
	ERROR_INVALID_KEYCODE_LETTER_LENGTH,
	ERROR_INVALID_KEYCODE_NUMBERS_LETTERS
} from '../../constants/product';

export const internalKeycodeCheck = (keyCode: string, areAllNumbers: boolean) => {
	if (areAllNumbers && keyCode.length !== 5 && keyCode.length !== 6) {
		return { invalid: true, message: ERROR_INVALID_KEYCODE_LENGTH };
	}
	if (!areAllNumbers && keyCode.length > 20) {
		return { invalid: true, message: ERROR_INVALID_KEYCODE_LETTER_LENGTH };
	}
	if (!containsOnlyLettersNumbersAndWhitespace(keyCode)) {
		return { invalid: true, message: ERROR_INVALID_KEYCODE_NUMBERS_LETTERS };
	}

	return null;
};

export const containsOnlyLettersNumbersAndWhitespace = (str: string) => {
	const allowedPattern = /^[a-zA-Z0-9\s]+$/;
	return allowedPattern.test(str);
};
