import gql from 'graphql-tag';
import { ImageFields, VariantFields } from '../common-fields.queries';

export const CartItemFields = gql`
	fragment CartItemFields on CartItem {
		id
		parentId
		brandName
		title
		modelNumber
		quantity
		stockCount
		url
		price {
			unitPrice
			discountUnitPrice
		}
		variant {
			...VariantFields
		}
		image {
			...ImageFields
		}
		pricedOptions {
			id
			name
			value
			keyCode
		}
		isSubItem
		hasProductSale
		rebates {
			rebateTitle
			rebateSubTotal
			rebateUrl
		}
	}
	${ImageFields}
	${VariantFields}
`;
