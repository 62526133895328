export const APPLY_COUPON_ERROR = 'Sorry, there was an error adding your coupon.';
export const REMOVE_COUPON_ERROR = 'Sorry, there was an error removing your coupon.';
export const REMOVE_ALL_COUPONS_ERROR =
	'Sorry, there was an error removing your coupons. They are not allowed because some cart items have Ferguson account pricing';
export const REMOVE_ALL_COUPONS_SUCCESS = 'Coupons have been removed because some cart items have Ferguson account pricing.';
export const DISCOUNT_REMOVED = 'Discount successfully removed.';
export const EPP_DISCOUNT_APPLIED = 'EPP discounts successfully applied to your cart';
export const MASS_ADD_ITEM_REQUIRED = 'Please enter at least one product to add to cart.';
export const APPLE_PAY_US_ONLY = 'Sorry, we can only ship to US with Apple Pay.';
export const EMAIL_CART_ERROR = 'An error occurred while attempting to email your cart. Please try again';
export const EMAIL_CART_SUCCESS = 'Cart Sent! The cart has been submitted to the recipient via email';
export const EMAIL_CART_RECIPIENTS_LIMIT = 'Please enter no more than 5 recipients';
export const EMAIL_CART_RECIPIENT_EMAIL_INVALID = 'Please verify all recipient emails are valid';
export const LOAD_CART_ERROR = 'An error occurred while attempting to load your saved cart.';
export const AMAZON_PAY_AUTHENTICATION_ERROR = 'Error authenticating with Amazon Pay. Please try again or use a different payment method.';
export const PAYPAL_AUTHORIZATION_ERROR = 'Error authorizing with PayPal. Please try again.';
export const SAVE_CART_SUCCESS = 'Cart Saved Successfully.';
export const MISSING_BILLING_ADDRESS = 'A billing address is required.';
export const INVALID_COMPLEX_ORDER_CART =
	'Complex Orders cannot include a combination of appliance and non-appliance items. Please review your cart and separate the items accordingly.';
