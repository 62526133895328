export const ERROR_LOADING_PRODUCT_CONFIGURATION = 'Error occurred while loading configurable options for your product.';
export const ERROR_INVALID_KEYCODE_LENGTH = 'Key codes are 5 or 6 numbers long.';
export const ERROR_INVALID_KEYCODE_LETTER_LENGTH = 'Key codes that include a letter may not be more than 20 characters long.';
export const ERROR_INVALID_KEYCODE_CHARACTERS = 'Key codes may only include numbers.';
export const ERROR_INVALID_KEYCODE_NUMBERS_LETTERS = 'Key codes may only include numbers or letters.';
export const ERROR_INVALID_KEYCODE = 'Invalid Key code.';
export const INFO_DISCONTINUED_PRODUCT_FAMILY = 'The selected finish has been discontinued and is no longer available.';
export const SUCCESS_ADD_FINISH_SAMPLE = 'You have added a finish sample to your cart.';
export const ERROR_ADD_FINISH_SAMPLE = 'An error has occurred while adding a finish sample to your cart.';
export const ERROR_ADD_ITEMS = 'We were unable to add your items';
export const REQUIRED_MESSAGE = 'This item is needed to complete installation';
