/* eslint-disable @typescript-eslint/consistent-type-assertions */
/**
 * Product Configuration Client Helpers
 */
import { kebabCase } from '@fergdigitalcommerce/fergy-utilities';
import { Dispatch, SetStateAction } from 'react';
import { RequiredPick } from '../../../../../@types/build';
import {
	AddToCartInput,
	AddToCartPricedOption,
	Maybe,
	ProductOptionFieldsFragment,
	ProductOptionGroupFieldsFragment,
	ProductOptionSourceType,
	ProductSelectionType
} from '../../../__generated__/graphql-client-types';
import { AddToCartAnalyticsInputData, ProductFamilyInputData } from '../../../hooks/add-to-cart/add-to-cart.hooks';
import { ProductFamily } from '../../../types/product.types';
import { ANALYTICS_PAGE_NAMES } from '../../analytics/analytics.helper';
import { ADD_TO_CART_BUTTON_TYPE } from '../add-to-cart-button/add-to-cart-button.helper';
import { changeOption } from './product-option.helper';

export enum OptionSelectionType {
	SINGLE,
	MULTIPLE,
	INDEPENDENT
}

export type OptionSelection = {
	id: string;
	name: string;
	type: OptionSelectionType;
	value: any;
	price: number;
	selected: boolean;
	quantity?: number;
	params?: Record<string, any>;
};

export type GroupSelection = {
	id: string;
	valid: boolean;
	selectionType: ProductSelectionType;
	options: OptionSelection[];
};

export type Selection = {
	id: number;
	price: number;
	quantity: number;
	groups: GroupSelection[];
	total: number;
	initialTotal?: number;
	isABundle?: boolean;
};

export type ProductOptionSelection = {
	selection?: Selection;
	update?: (option: ProductOptionFieldsFragment, optionSelection: OptionSelection, group?: Pick<GroupSelection, 'id' | 'valid'>) => void;
	changeSelection?: Dispatch<SetStateAction<Selection>>;
};

export type AddToCartSelection<T> = {
	pricedOptions: T[];
	subItems?: AddToCartInput[];
};

export type UpdateSelectionParams = {
	option: ProductOptionFieldsFragment;
	optionSelection: OptionSelection;
	groupSelection?: GroupSelection;
};

export const byId = <T extends { id: number | string }>(current: T, given: T): boolean => current.id === given.id;

const addOption = (group: GroupSelection, option: OptionSelection): GroupSelection => {
	return { ...group, options: [...group.options, option] };
};

const removeOption = (group: GroupSelection, option: OptionSelection): GroupSelection => {
	return { ...group, options: group.options.filter((current) => !byId(current, option)) };
};

const resetSingleSelectionOptions = (group: GroupSelection): GroupSelection => {
	return {
		...group,
		options: group.options.map((option) => (option.type === OptionSelectionType.SINGLE ? { ...option, selected: false } : option))
	};
};

const updateOption = (group: GroupSelection, updatedOption: OptionSelection): GroupSelection => {
	group = updatedOption.type === OptionSelectionType.SINGLE ? resetSingleSelectionOptions(group) : group;
	return addOption(removeOption(group, updatedOption), updatedOption);
};

const updateGroup = (selection: Selection, updatedGroup: GroupSelection): Selection => {
	const filteredGroups = selection.groups.filter((existing) => existing.id !== updatedGroup.id);
	return { ...selection, groups: [...filteredGroups, updatedGroup] };
};

const isOptionValueBased = (option: OptionSelection): boolean => {
	return option.type === OptionSelectionType.INDEPENDENT && option.value && option.value.length > 0;
};

export const findGroupBy = (selection: Selection, predicate: (current: GroupSelection) => boolean): GroupSelection | null => {
	return selection.groups.find((group) => predicate(group)) || null;
};

export const findGroupSelectionById = (id: string, selection: Selection | undefined): GroupSelection | null =>
	selection ? findGroupBy(selection, (group) => group.id === id) : null;

export const findGroupAndOption = (
	selection: Selection,
	option: OptionSelection
): { group: GroupSelection; option: OptionSelection } | null => {
	let optionFound: OptionSelection | null = null;
	const groupFound = findGroupBy(selection, (group) => {
		optionFound = optionFound === null ? group.options.find((current) => byId(current, option)) || null : optionFound;
		return Boolean(optionFound);
	});
	return groupFound && optionFound ? { group: groupFound, option: optionFound } : null;
};

export const filterOptionsBy = (
	predicate: (group: GroupSelection, option: OptionSelection) => boolean,
	selection?: Selection
): OptionSelection[] => {
	return selection && selection.groups && Array.isArray(selection.groups)
		? selection.groups.flatMap((group) => group.options.filter((option) => predicate(group, option)))
		: [];
};

export const updateSelection = (
	selection: Selection,
	updatedOption: OptionSelection,
	updatedGroup?: RequiredPick<Partial<GroupSelection>, 'id'>
): Selection => {
	const currentGroupToUpdate = updatedGroup ? findGroupSelectionById(updatedGroup.id, selection) : null;
	const found = currentGroupToUpdate
		? { group: { ...currentGroupToUpdate, ...updatedGroup }, option: updatedOption }
		: findGroupAndOption(selection, updatedOption);
	return found ? updateSelectionTotal(updateGroup(selection, updateOption(found.group, updatedOption))) : selection;
};

export const updateSelectionTotal = (selection: Selection): Selection => {
	const grandTotal = selection.groups.reduce((total, group) => {
		total += group.options.reduce((subTotal, option) => {
			const nestedInfo = option?.params?.nested;
			subTotal += isOptionSelected(option) ? option.price * (nestedInfo?.selectedQuantity || option.quantity || 1) : 0;
			return subTotal;
		}, 0);
		return total;
	}, selection.initialTotal || 0);
	return { ...selection, total: grandTotal };
};

export const updateSelections = (familyId: number, currentSelection: Selection, updateParams: UpdateSelectionParams): Selection => {
	const { option, optionSelection, groupSelection } = updateParams;
	const optionParams = { id: familyId, variantId: currentSelection.id, variantPrice: currentSelection.price };
	const updatedOptionSelection = { ...optionSelection, params: { ...optionSelection.params, ...optionParams } };
	return updateSelection(currentSelection, resolveUpdatedOption(option, updatedOptionSelection), groupSelection);
};

export const calculateTotalItems = (selection: Selection, initialValue?: number) => {
	return filterOptionsBy(isNestedOptionSelected, selection).reduce((total, item) => {
		total += item.params?.nested?.selectedQuantity || 1;
		return total;
	}, initialValue || selection.quantity);
};

export const isConfigurationValid = (selection: Selection): boolean => {
	return selection.groups.every((group) => group.valid);
};

export const isNestedOptionSelected = (group: GroupSelection, option: OptionSelection): boolean => {
	return group.selectionType === 'NESTED' && option.selected && Boolean(option.quantity && option.quantity > 0);
};

export const isOptionSelected = (option: OptionSelection): boolean => {
	return (
		(option.selected && option.type !== OptionSelectionType.INDEPENDENT) ||
		(option.type === OptionSelectionType.INDEPENDENT && option.value && option.value.length > 0)
	);
};

export const generateSelectionFromRecommendedGroups = (
	groups: ProductOptionGroupFieldsFragment[],
	params?: Record<string, any>
): Selection => {
	const onlyRecommended = groups.filter((group) => group.type === 'RECOMMENDED');
	return {
		id: params?.variantId || 0,
		price: params?.variantPrice || 0,
		quantity: params?.variantQuantity || 1,
		groups: onlyRecommended.map((group) => ({
			id: kebabCase(group.label),
			valid: true,
			selectionType: group.selectionType,
			options: group.options.map((option) => getOptionSelectionFromRecommendedOption(option, params))
		})),
		total: params?.total || 0
	};
};

export const getOptionSelectionFromRecommendedOption = (
	option: ProductOptionFieldsFragment,
	params?: Record<string, any>
): OptionSelection => {
	return {
		id: kebabCase(option.name),
		name: option.name,
		type: OptionSelectionType.INDEPENDENT,
		value: option.value,
		selected: option.selected,
		quantity: option.resource?.quantity || 1,
		price: option.resource?.price?.current || 0,
		params
	};
};

export const generateSelectionFromRequiredGroups = (
	groups: ProductOptionGroupFieldsFragment[],
	params?: Record<string, any>,
	isABundle?: boolean
): Selection => {
	const onlyRequired = groups.filter((group) => group.type === 'REQUIRED');
	const quantity = params?.variantQuantity || 1;
	const price = params?.variantPrice || 0;
	const initialTotal = quantity * price;
	return updateSelectionTotal({
		id: params?.variantId,
		price: params?.variantPrice,
		quantity,
		groups: onlyRequired.map((group) => {
			const options = group.options.map((option) => getOptionSelectionFromRequiredOption(group, option, params));
			const valid =
				group.selectionType === 'INLINE' || group.selectionType === 'COMBINED'
					? options.some((option) => isOptionSelected(option))
					: true;
			return { id: kebabCase(group.label), selectionType: group.selectionType, valid, options };
		}),
		initialTotal,
		total: initialTotal,
		isABundle
	});
};

export const getOptionSelectionFromRequiredOption = (
	group: ProductOptionGroupFieldsFragment,
	option: ProductOptionFieldsFragment,
	params?: Record<string, any>
): OptionSelection => {
	const isNested = group.selectionType === 'NESTED';
	const isCombined = group.selectionType === 'COMBINED';
	const isTextField = option.type === 'TEXTBOX';
	const isCheckbox = option.type === 'CHECKBOX';
	const optionSelection = isTextField ? OptionSelectionType.INDEPENDENT : OptionSelectionType.SINGLE;
	return {
		id: kebabCase(option.name),
		name: option.name,
		type: isCheckbox ? OptionSelectionType.MULTIPLE : optionSelection,
		value: isTextField ? '' : option.value,
		selected: option.selected,
		quantity: (isNested || isCombined) && option.resource?.quantity ? option.resource.quantity : 0,
		price: option.resource?.price?.current || 0,
		params: { ...params, optionId: Number(option.value) }
	};
};

export const isSelectedOptionValueBased = (option: OptionSelection): boolean => {
	const { selected, params } = option;
	const isValueBased = isOptionValueBased(option);
	return params?.optionId && ((selected && !isValueBased) || isValueBased);
};

export const resolveUpdatedOption = (option: ProductOptionFieldsFragment, optionSelection: OptionSelection): OptionSelection => {
	return { ...optionSelection, value: option.value, selected: option.selected };
};

export const defaultSerializer = <T extends AddToCartPricedOption>(option: OptionSelection): T | null => {
	const isValueBased = isOptionValueBased(option);
	if (option.params?.optionId) {
		if (option.selected && !isValueBased) {
			return { pricedOptionId: option.params.optionId, pricedOptionText: null } as T;
		} else if (isValueBased) {
			return {
				pricedOptionId: option.params.optionId,
				pricedOptionText: isValueBased ? option.value.toString() : null
			} as T;
		}
	}
	return null;
};

export const serializeNestedOption = (option: OptionSelection): AddToCartInput | null => {
	const { selectedVariantId, selectedQuantity } = option.params?.nested || {};
	return selectedVariantId && selectedQuantity && option.selected
		? { variantId: Number(selectedVariantId), quantity: selectedQuantity }
		: null;
};

export const serializeSelection = <T extends AddToCartPricedOption>(
	selection: Selection,
	serializer: (option: OptionSelection) => T | null = defaultSerializer
): AddToCartSelection<T> => {
	return selection.groups.reduce(
		(result, group) => {
			return {
				...result,
				...(group.selectionType === 'NESTED'
					? {
							subItems: [
								...(result.subItems || []),
								...(group.options.map(serializeNestedOption).filter(Boolean) as AddToCartInput[])
							]
					  }
					: {
							pricedOptions: [...result.pricedOptions, ...(group.options.map(serializer).filter(Boolean) as T[])]
					  })
			};
		},
		{ pricedOptions: [], subItems: [] } as AddToCartSelection<T>
	);
};

export const getConfigurableProductAnalyticsData = (
	params: Pick<AddToCartAnalyticsInputData, 'variantId' | 'quantity'>,
	productFamily: ProductFamilyInputData,
	pageName: ANALYTICS_PAGE_NAMES
): AddToCartAnalyticsInputData => {
	return {
		...params,
		...productFamily,
		pageName,
		link: 'Add to Cart',
		linkName: 'Add to Cart'
	};
};

export const checkRecommendedType = (sources: ProductOptionSourceType[]): string => {
	const prepedSourceList: string[] = [];
	if (sources.length === 0) {
		prepedSourceList.push('mws-dynamic');
	}
	sources.forEach((source) => {
		if (source === 'REC_OPS') {
			prepedSourceList.push('rec-ops');
		} else if (source === 'MAY_WE_SUGGEST') {
			prepedSourceList.push('mws-manual');
		} else {
			prepedSourceList.push('mws-dynamic');
		}
	});
	return prepedSourceList.join(', ');
};

export const extractRecommendedOptionSourceType = (recommended: ProductOptionGroupFieldsFragment[]): ProductOptionSourceType[] => {
	const sourceTypes: ProductOptionSourceType[] = ['DYNAMIC_YIELD', 'MAY_WE_SUGGEST', 'REC_OPS'];
	const availableSourceTypes: ProductOptionSourceType[] = [];
	recommended.forEach((option) => {
		if (sourceTypes.includes(option.source) && !availableSourceTypes.includes(option.source)) {
			availableSourceTypes.push(option.source);
		}
	});
	return availableSourceTypes;
};

export const updateGroupOption = (
	update,
	selectedNestedGroup: GroupSelection | null,
	selectedProduct?: ProductOptionFieldsFragment | null,
	selection?: Selection,
	selectedOption?: OptionSelection | null
): void => {
	if (update && selectedProduct && selection && selectedNestedGroup && selectedOption) {
		update(changeOption({ ...selectedProduct, value: null }), selectedOption, {
			...selectedNestedGroup
		});
	}
};

export const generateATCAnalyticsData = (
	variantId: number,
	quantity: number,
	productFamily: Maybe<ProductFamily>,
	analyticsData?: AddToCartAnalyticsInputData,
	addToCartButtonType?: ADD_TO_CART_BUTTON_TYPE
) => {
	if (analyticsData) {
		return analyticsData;
	} else if (addToCartButtonType === ADD_TO_CART_BUTTON_TYPE.STICKY) {
		return getConfigurableProductAnalyticsData(
			{ variantId, quantity },
			{ productFamily },
			ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE_STICKY
		);
	} else {
		return getConfigurableProductAnalyticsData({ variantId, quantity }, { productFamily }, ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE);
	}
};
